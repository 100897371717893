/* eslint-disable */
import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

//款式供应商关联
export function styleBatchSet(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/supplier/styleBatchSet',
    method: 'post',
    data
  })
}

//款式供应商关联列表展示
export function styleList(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/supplier/styleList',
    method: 'post',
    data
  })
}


//导出供应商
export function supplierExport(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/supplier/supplierExport',
    method: 'post',
    responseType:'blob',
    data
  })
}


//导入供应商
export function supplierImport(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/supplier/supplierImport',
    method: 'post',
    data
  })
}

//添加供应商
export function createSupplier(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/supplier/createSupplier',
    method: 'post',
    data
  })
}

//供应商列表(supplierList)
export function supplierList(data, menuType = 'menu') {
  return request({
    headers: getXMenuType(menuType),
    // url: '/externaladmin/productService/image/list',
    url: '/externaladmin/authService/supplier/supplierList',
    method: 'post',
    data
  })
}

//更新一条供应商数据记录
export function supplierUpdate(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/supplier/supplierUpdate',
    method: 'post',
    data
  })
}

export function add(data, menuType = 'btn') {
  return refreshDic(
    request({
      headers: getXMenuType(menuType),
      url: '/externaladmin/authService/supplier/supplierCreate',
      method: 'post',
      data
    }),
    'supplierId'
  )
}

export function edit(data, menuType = 'btn') {
  return refreshDic(
    request({
      headers: getXMenuType(menuType),
      url: '/externaladmin/authService/supplier/supplierUpdate',
      method: 'post',
      data
    }),
    'supplierId'
  )
}

export function read(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/authService/supplier/getById',
    method: 'post',
    data
  })
}

export function supplier(params) {
  return request({
    url: '/api/rbac/common_users?main_user_by_role=供应商',
    method: 'get',
    params
  })
}

