import { baseProtoFormField, basePictureFormField } from './field'
import { getAllData, getOpenList, getDistributionList } from '@/views/product/maintain/basicsMange/module/api'
import { createProtoGroup, updateProtoGroup, addPrototypeUpdate, assignGroup } from '@/api/product/protoGroupApi'
import { createPictureGroup, updatePictureGroup } from '@/api/image/pictureGroupApi'
import { styleList, styleBatchSet } from '@/api/order/supplier'
import { cloneDeep } from 'lodash'
import { checkPermission } from '@/utils'
import { TYPE_IS_PROTO, TYPE_IS_PICTRUE } from '@/utils/constant'

export const prototypeDialogTableOption = {
  searchText: '搜索',
  isSearchIcon: false,
  searchSpan: 10,
  topPage: false,
  menu: false,
  selection: true,
  column: [
    {
      label: '款式',
      prop: 'styleNameListStr',
      searchPlaceholder: '多个款式用逗号或者空格隔开',
      hide: true,
      search: true
    },
    {
      label: '原型分类',
      prop: 'platformCategoryId',
      hide: true,
      search: true,
      type: 'cascader',
      dicType: 'proCategory'
    },
    {
      label: '原型名称/图片',
      prop: 'imgText',
      slot: true
    },
    {
      label: '原型分类',
      prop: 'productCategoryId',
      type: 'cascader',
      slot: true,
      dicType: 'proCategory'
    },
    {
      label: '款式',
      prop: '$styleName'
    },
    {
      label: '尺码',
      prop: '$sizeName'
    }
  ]
}

export const protoDistributorDialogTableOption = {
  btn: false,
  isSearchIcon: false,
  searchSpan: 10,
  topPage: false,
  searchLabelWidth: '110px',
  menu: false,
  selection: true,
  column: [
    {
      label: '原型分组名称',
      prop: 'name',
      search: true,
      hide: true,
      searchFormSlot: true
    },
    {
      label: '分组原型数',
      prop: 'count',
      search: true,
      hide: true,
      searchFormSlot: true
    },
    {
      label: '分销商名称',
      prop: 'nickName'
    },
    {
      label: '登录账号',
      prop: 'accountName',
      search: true
    },
    {
      label: '分销商等级',
      prop: 'memberLevelId',
      slot: true
    },
    {
      label: '分销商账号',
      prop: 'accountName'
    }
  ]
}

export const pictureDialogTableOption = {
  searchText: '搜索',
  isSearchIcon: false,
  searchSpan: 10,
  topPage: false,
  menu: false,
  selection: true,
  column: [
    {
      label: '图片名称',
      prop: 'titleList',
      search: true,
      hide: true
    },
    {
      label: '图片名称',
      prop: 'title'
    },
    {
      label: '图片',
      prop: 'thumbnailPath',
      slot: true
    },
    {
      label: '图片分类',
      prop: 'categoryId',
      search: true,
      type: 'cascader',
      dicType: 'category'
    }
  ]
}

export const poctureDistributorDialogTableOption = {
  btn: false,
  isSearchIcon: false,
  searchSpan: 10,
  topPage: false,
  searchLabelWidth: '110px',
  menu: false,
  selection: true,
  column: [
    {
      label: '图片分组名称',
      prop: 'name',
      search: true,
      hide: true,
      searchFormSlot: true
    },
    {
      label: '分组图片数',
      prop: 'count',
      search: true,
      hide: true,
      searchFormSlot: true
    },
    {
      label: '分销商名称',
      prop: 'nickName'
    },
    {
      label: '登录账号',
      prop: 'accountName',
      search: true
    },
    {
      label: '分销商等级',
      prop: 'memberLevelId',
      slot: true
    },
    {
      label: '分销商账号',
      prop: 'accountName'
    }
  ]
}

export const optionsList = {
  picture: {
    title: '图片',
    nameProp: 'title',
    permisionList: {
      hasAdd: checkPermission(['externaladmin:publicGalleryGroup:addGroup:add']),
      hasEdit: checkPermission(['externaladmin:publicGalleryGroup:editGroup:edit'])
    },
    tableOption: cloneDeep(pictureDialogTableOption),
    getListFn: getOpenList,
    addSubmitFuncApi(data) {
      const { name } = this.form
      return createPictureGroup({
        name,
        imageIdList: data
      })
    },
    editSubmitFuncApi(data) {
      const { name, id } = this.form
      return updatePictureGroup({
        id,
        name,
        imageIdList: data
      })
    },
    componentName: 'PictureItem',
    baseFormField: basePictureFormField
  },

  //原型分配分销商
  protoDistributor: {
    title: '分销商',
    nameProp: 'title',
    permisionList: {
      hasAdd: checkPermission(['externaladmin:protoGroup:assignProtoDistributor:add'])
    },
    tableOption: cloneDeep(protoDistributorDialogTableOption),
    getListFn: getDistributionList,
    addSubmitFuncApi(data) {
      const { id: groupId } = this.info
      return assignGroup({
        groupId,
        type: TYPE_IS_PROTO,
        userIdList: data
      })
    },
    componentName: 'DistributorItem'
  },

  //图片分配分销商
  pictureDistributor: {
    title: '分销商',
    nameProp: 'title',
    permisionList: {
      hasAdd: checkPermission(['externaladmin:publicGalleryGroup:assignProtoDistributor:add'])
    },
    tableOption: cloneDeep(poctureDistributorDialogTableOption),
    getListFn: getDistributionList,
    addSubmitFuncApi(data) {
      const { id: groupId } = this.info
      return assignGroup({
        groupId,
        type: TYPE_IS_PICTRUE,
        userIdList: data
      })
    },
    componentName: 'DistributorItem'
  },

  // 关联款式
  associateStyle: {
    title: '关联的款式',
    selectTitle: '已选择款式',
    nameProp: 'material',
    permisionList: {
      hasEdit: true
    },
    tableOption: {
      btn: false,
      isSearchIcon: false,
      searchSpan: 10,
      topPage: false,
      searchLabelWidth: '110px',
      menu: false,
      selection: true,
      column: [
        {
          label: '款式名称',
          prop: 'name',
          search: true,
          hide: true
        },
        {
          label: '款式名称',
          prop: 'styleName'
        },
        {
          label: '原型分类',
          prop: 'productCategoryId',
          type: 'cascader',
          dicType: 'proCategory',
          hide: true,
          search: true
        },
        {
          label: '所属分类',
          prop: 'categoryName'
        }
      ]
    },
    getListFn: styleList,
    addSubmitFuncApi(data) {
      const { id } = this.info
      return styleBatchSet({
        supplierId: id,
        styleIdList: data
      })
    },
    editSubmitFuncApi(data) {
      const { id } = this.info
      return styleBatchSet({
        supplierId: id,
        styleIdList: data
      })
    },
    componentName: 'DistributorItem',
    componentProp: 'styleName'
  },

  default: {
    title: '原型',
    nameProp: 'name',
    permisionList: {
      hasAdd: checkPermission(['externaladmin:protoGroup:addGroup:add']),
      hasEdit: checkPermission(['externaladmin:protoGroup:editGroup:edit']),
      hasExtend: checkPermission(['externaladmin:protoGroup:editGroup:extend'])
    },
    tableOption: cloneDeep(prototypeDialogTableOption),
    getListFn: getAllData,
    addSubmitFuncApi(data) {
      const { name } = this.form
      return createProtoGroup({
        name,
        prototypeIdList: data
      })
    },
    editSubmitFuncApi(data) {
      const { name, id } = this.form
      return updateProtoGroup({
        id,
        name,
        prototypeIdList: data
      })
    },
    extendAddSubmitFuncApi(data) {
      const { name, id } = this.form
      return addPrototypeUpdate({
        id,
        name,
        prototypeIdList: data
      })
    },
    componentName: 'ProtoItem',
    baseFormField: baseProtoFormField
  }
}

export function getOption(type) {
  return optionsList[type]
}
